<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">电商通微信公众号</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="add()">添加电商通微信公众号</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="c-title">数据列表</div> -->
      <el-table :data="dataList" size="mini">
        <el-table-column label="id" prop="id" width="50px"> </el-table-column>
        <el-table-column label="微信公众号名称" prop="officailName" width="240px">
        </el-table-column>
        <el-table-column label="微信公众号appid" prop="officailAppid" width="150px"></el-table-column>
        <!-- <el-table-column label="微信公众号密钥" prop="officailAppSecret" width="150px"></el-table-column> -->
        <el-table-column label="状态" width="120px">
          <template slot-scope="s">
            <el-switch :value="s.row.status" :active-value="1" :inactive-value="0" inactive-color="#ff4949" @change="setStatus(s.row)"></el-switch>
            <b style="color: green; margin-left: 10px" v-if="s.row.status == 1">启用</b>
            <b style="color: red; margin-left: 10px" v-if="s.row.status == 0">禁用</b>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <AddOfficailAcount ref="add-officail-account"></AddOfficailAcount>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import AddOfficailAcount from "./add-officail-account.vue";

export default {
  components: { AddOfficailAcount },
  data() {
    return {
      p: {
        type: 2,
        page: 1,
        limit: 10,
      },
      title: "添加服务商",
      userDialogVisible: false,
      dataCount: 0,
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.sa_admin.role == 3) {
        this.p.mchId = this.sa_admin.mchId;
      }
      this.sa.ajax(
        "/v1/officail/list",
        this.p,
        function (res) {
          this.dataList = res.data.records; // 数据
          this.dataCount = res.data.total; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    // 删除
    del: function (data) {
      var defaultCfg = {
        type: 'delete', //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/officail/delete/" + data.officailAppid,
        function () {
          this.claerRedisData();
          this.f5();
        }.bind(this), defaultCfg
      );
    },
    setStatus: function (data) {
      this.sa.confirm(
        "是否要修改公众号启用状态",
        function () {
          var defaultCfg = {
            type: "get", //是否是请求体请求
          };
          var p = {
            status: data.status, //是否是请求体请求
          };
          var params = data;
          this.sa.ajax(
            "/v1/officail/setStatus/" + data.id, p,
            function () {
              this.sa.ok("修改成功");
              params.status = params.status == 1 ? 0 : 1;
              this.claerRedisData();
            }.bind(this), defaultCfg
          );
        }.bind(this)
      );
    },
    claerRedisData() {
      var defaultCfg = {
        type: "get",
      };
      this.sa.ajax(
        "/v2/admin/cleanProviderInfoAll",
        this.m,
        function () {
        },
        defaultCfg
      );
    },
    add: function () {
      this.$refs["add-officail-account"].open(0, 2);
    },
    update: function (data) {
      this.$refs["add-officail-account"].open(data, 2);
    },
  },
  created: function () {
    this.f5();
  },
};
</script>

<style>
</style>
